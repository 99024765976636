import React from "react";
import Header from "../Header/Headerpt.js";
import Section from "../Section/Section.js";
import Card from "../Card/Card.js";
import Card2 from "../Card2/Card2.js";
import Card3 from "../Card3/Card3.js";
import Site from "../SiteCard/Site.js";
import Contato from "../Contato/Contato.js";
import Faq from "../Faq/Faq.js";
import Footer from '../Footer/Footer.js';
import Local from '../Local/Local.js';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import VideoPT from './videopt.js';
import VideoES from './videoes.js';
import VideoEN from './videoen.js';
import VideoFR from './videofr.js';
import { Link } from 'react-router-dom';

const VideoContainer = styled.div`
  width: 100%;
`;

const Luz = styled.div`
  position: absolute;
  z-index: -1;
  width: 400px;
  height: 240px;
  background: rgba(255, 194, 34, 0.5);
  border-radius: 100px;
  filter: blur(200px); /* Valor do desfoque, ajuste conforme necessário */
  top: -100px; /* Ajuste conforme necessário para a posição desejada */
  left: 1100px; /* Ajuste conforme necessário para a posição desejada */

  animation: pulse3 2s infinite alternate;
  
  @keyframes pulse3 {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: 1000px) {
        display: none;
    }

`;

const HomeContainer = styled.div`
  z-index: 1;
  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;

const Divi = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  background: #151515;
`;

const StyledLink2 = styled.a`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin:1rem;
  text-align: center;
`;

const TitleSub23 = styled.button`
  width: 200px;
  height: 50px;
  background: none;
  cursor: pointer;
  font-weight: 600;
  color: white;
  font-size: 1.2rem;
  border-radius: 8px;
  border: 1px solid #FFC222;
  transition: 500ms;
  margin: 8px;

  &:hover{
    background: #FFC222;
  }
`;

const Linkada = styled.a`
  color: #FFC222;
  display: block;
  font-size: 1.2rem;
  text-align: center;
  background: #151515;
`;

function Home() {
  const { t } = useTranslation();


  return (
    <HomeContainer>
      <Luz></Luz>
      <Header/>
      <Section/>
      <Card3/>
      <Card/>
      <Card2/>
      <Site/>

      <Divi>
        <StyledLink2 href="/faq-infinox">
            <TitleSub23>FAQ INFINOX</TitleSub23>
        </StyledLink2>
        <StyledLink2 href="/ganhos-infinox">
            <TitleSub23>{t('ganhos.infinox.text')}</TitleSub23>
        </StyledLink2>
      </Divi>
      <Contato/>
      <Faq/>
      <Linkada href="https://br.trustpilot.com/" target="_blank">{t('trustpilot')}</Linkada>
      <Footer/>
    </HomeContainer>
  );
}

export default Home;
