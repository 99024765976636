import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MenuIcone from '../../assets/logo.png';
import Brazilicon from '../../assets/icon/brazilIcon.png';
import Usaicon from '../../assets/icon/usaIcon.png';
import SpanishIcon from '../../assets/icon/spanishIcon.png';
import FranceIcon from '../../assets/icon/franceIcon.png';
import ArabeIcon from '../../assets/icon/arabeIcon.png';
import ChinaIcon from '../../assets/icon/chinaIcon.png';
import icon from '../../assets/icon/icon.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Linkada = styled(Link)`

`;


const StyledLink = styled(Link)`
  width: 100%;
  max-width: 1080px;
  text-decoration: none;
`;


const Container = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
`;

const Menu = styled.div`
  z-index: 3;
  width: 100%;
  max-width: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  background: none;
`;

const IconMenu = styled.img`
  width: 100%;
  max-width: 350px;
  margin-right: 16px;
`;

const TitleMenu = styled.h1`
  color: white;
  font-weight: 600;
  font-size: 1.8rem;
`;


const Button = styled.button`
  background: ${props => (props.selected ? '#232323' : '#FFC222')};
  color: ${props => (props.selected ? '#ffffff' : '#ffffff')};
  border: none;
  border-radius: 4px;
  padding: 10px;
  width: 120px;
  cursor: pointer;
  margin: 15px  20px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1000;
  transition: 500ms;
  animation: pulse2 500ms infinite alternate;

  :hover {
    filter: brightness(0.9);

    @keyframes pulse2 {
    from {
      opacity: 0.8;
      transform: scale(1);
    }
    to {
      opacity: 1;
      transform: scale(1.1);
    }
  }

  }

  img{
    width: 20px;
    height: 20px;
    background: none;
    margin-right: 10px;
  }

  p{
    font-size: 1rem;
    background: none;
    margin-top: 2px;
    text-transform: uppercase;
    font-weight: 600;
  }

  @media (max-width: 1000px) {
    margin: 15px  30px 18px;
    width: 100px;
    p{
    font-size: 1rem; 
    }

    img{
      width: 15px;
      height: 15px;
    }
  }


`;

const Dropdown = styled.div`
  z-index: 5;
  position: relative;
  display: inline-block;
  margin-left: 2rem;
`;

const DropdownContent = styled.div`
  display: ${props => (props.open ? 'block' : 'none')};
  position: absolute;
  background-color:#232323;
  min-width: 160px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 1.5rem;
  top: 4rem;
  border-radius: 4px;
  z-index: 1000;
`;

const DropdownItem = styled.div`
  z-index: 1000;
  padding: 0.5rem;
  position: relative;
  background: none;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 4px;
  &:hover {
    background-color: #4491FA;
  }

  img {
    display: inline;
    width: 24px;
    height: 24px;
    background: none;
    margin-top: 4px;
    margin-right: 8px;
  }

  p {
    position: absolute;
    display: inline;
    font-size: 1rem;
    background: none;
    margin-top: 5px;
    color: white;
    font-weight: 400;
  }
`;


function Header() {
  const [selectedLanguage, setSelectedLanguage] = useState('es');
  const [dropdownOpen, setDropdownOpen] = useState(false);


  const { i18n } = useTranslation();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLanguageChange = (language) => {
    localStorage.setItem('selectedLanguage', language);
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    setDropdownOpen(false);
  };


  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    const defaultLanguage = 'es-es'; // Idioma padrão caso nenhum seja encontrado no localStorage
    const languageToSet = storedLanguage || defaultLanguage; // Usar o idioma padrão se nenhum estiver armazenado
    
    setSelectedLanguage(languageToSet);
    i18n.changeLanguage(languageToSet);
  }, [i18n.language]);
  


  return (
   <Container>
          <StyledLink to="">
          <Menu>
              <IconMenu src={MenuIcone} title="Robot" alt="Robot"/>
          </Menu>
          </StyledLink>
      <Dropdown alt="Idiomas" title="Idiomas">
        <Button selected={dropdownOpen} onClick={toggleDropdown}>
          <img  src={icon} alt="Icon"/>
          <p>{selectedLanguage}</p>
        </Button>
        <DropdownContent open={dropdownOpen}>

        <Linkada to="/pt">
          <DropdownItem onClick={() => handleLanguageChange('pt-br')}>
            <img src={Brazilicon} alt='IcontCont'/>
            <p>PT-BR</p>
          </DropdownItem>
        </Linkada>

          <Linkada to="/en">
          <DropdownItem onClick={() => handleLanguageChange('en-us')}>
            <img src={Usaicon} alt='IcontCont'/>
            <p>EN-US</p>
          </DropdownItem>
          </Linkada>

          <Linkada to="/es">
          <DropdownItem onClick={() => handleLanguageChange('es-es')}>
            <img src={SpanishIcon} alt='IcontCont'/>
            <p>ES-ES</p>
          </DropdownItem>
          </Linkada>

          <Linkada to="/fr">
          <DropdownItem onClick={() => handleLanguageChange('fr-fr')}>
            <img src={FranceIcon} alt='IcontCont'/>
            <p>FR-FR</p>
          </DropdownItem>
          </Linkada>

          <Linkada to="/ar">
          <DropdownItem onClick={() => handleLanguageChange('ar-ar')}>
            <img src={ArabeIcon} alt='IcontCont'/>
            <p>AR-AR</p>
          </DropdownItem>
          </Linkada>

          <Linkada to="/cn">
          <DropdownItem onClick={() => handleLanguageChange('cn-cn')}>
            <img src={ChinaIcon} alt='IcontCont'/>
            <p>ZH-CN</p>
          </DropdownItem>
          </Linkada>

        </DropdownContent>
      </Dropdown>
   </Container>
  );
}

export default Header;
