import React from "react";
import styled from 'styled-components';
import MenuIcone from '../../assets/logo.png';
import MenuIconTopo from '../../assets/logo-topo.png';
import IconeZap from '../../assets/WHATSAPP.png';
import IconeInsta from '../../assets/INSTAGRAM.png';
import FotoRobo from '../../assets/ROBO-ART-2.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  } 
  `;


const Container = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 0;
  position: relative;
  overflow-x: hidden !important;
`;

const ConstrollCard = styled.div`
    z-index: 3;
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: row;

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
    }
`;

const Card = styled.div`  
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
`;

const IconMenu1 = styled.img`
  width: 100%;
  max-width: 167px;
`;


const IconMenu2 = styled.img`
  width: 100%;
  max-width: 40px;
  height: 40px;
  margin-right: 16px;
`;

const CardText = styled.div`
    display: flex;
    justify-content: center;
    max-width: 100%;
    width: 350px;
`;

const CardTitle1 = styled.p`
    color: #c3c3c3;
    text-align: left;
    font-size: 42px;
    font-weight: 400;
    margin: 6px;
`;


const HighlightedText = styled.span`
  font-weight: bold;
  color: white;
  font-size: 42px;
`;

const HighlightedText1 = styled.span`
  font-weight: bold;
  background: linear-gradient(to right, #bf8b3d, #fefebe, #bf8b3d);
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  margin-right: 10px;
  font-size: 42px;
`;


const ControleBotao = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 40px;
`;

const Começar = styled.button`
    border: none;
    padding: 1rem;
    border-radius: 20px;
    width: 190px;
    margin-right: 14px;
    cursor: pointer;
    background: white;
    font-weight: bold;
    font-size: 1.3rem;
    transition: 500ms;
    animation: pulse 500ms infinite alternate;
  
  @keyframes pulse {
    from {
      opacity: 0.8;
      transform: scale(1);
    }
    to {
      opacity: 1;
      transform: scale(1.1);
    }
  }
`;

const ControlRedes = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 3rem;
`;

const IconRedes = styled.img`
    margin: 4px;
    cursor: pointer;
`;

const TitleRedes = styled.p`
    color: #c3c3c3;
    margin-right: 30px;
    font-weight: 600;
`;


const CardMeio = styled.div`
    width:600px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Imagem = styled.img`
  width: 450px;
  margin-bottom: 2rem;
  transform: translate(-50,-50);
  animation: bounceAnimation 2s ease-in-out infinite alternate;

  @media (max-width: 1000px) {
    margin-top: 2rem;
    width: 300px;
  }

  @keyframes bounceAnimation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-5px);
    }
  }
`;



const ControlInfo = styled.div`
`;

const TitleInfo = styled.p`
    color: white;
    font-weight: 600;
    font-size: 2rem;
    margin-top: 2rem;
`;

const SubTitle = styled.p`
    color: #c3c3c3;
    font-weight: 400;
    font-size: 1.3rem;
`;




function Section() {
  const { t } = useTranslation();


  return (
    <Container>
      <ConstrollCard>
        <Card>  
          <CardText>
            <CardTitle1>
            <HighlightedText1>{t('header.title1')} </HighlightedText1>
            {t('header.title2')} <HighlightedText>  {t('header.title3')}</HighlightedText> 
            {t('header.title4')} {t('header.title5')} <HighlightedText>{t('header.title6')}</HighlightedText>
            </CardTitle1>
            </CardText>

            <StyledLink target="_blank" to="https://api.whatsapp.com/send?phone=5511944504912">
                <ControleBotao>
                    <Começar title="Começar" alt="Começar">{t('header.button')}</Começar>
                </ControleBotao>
            </StyledLink>


            <ControlRedes>  
                    <TitleRedes>{t('header.redes')}</TitleRedes>
                    <a target="_blank" rel="noreferrer"  href="https://api.whatsapp.com/send?phone=5511944504912">
                      <IconRedes src={IconeZap} title="Whatsapp" alt="Whats"/>
                    </a>
                    <a target="_blank" rel="noreferrer"  href="https://www.instagram.com/rise_robo/">
                      <IconRedes src={IconeInsta} title="Instagram" alt="instagram"/>
                    </a>
            </ControlRedes>
        </Card>
        
        <CardMeio>
            <Imagem src={FotoRobo} alt="Robo"/>
        </CardMeio>

        <ControlInfo>
            <TitleInfo>{t('header.card3.title1')}</TitleInfo>
            <SubTitle>{t('header.card3.sub1')} </SubTitle>

            <TitleInfo>{t('header.card3.title2')}</TitleInfo>
            <SubTitle>{t('header.card3.sub2')}</SubTitle>
        </ControlInfo>
      </ConstrollCard>
    </Container>
  );
}

export default Section;







